
import SilderItem from "./SilderItem.vue";

export default {
  name: "CustomSlider",
  components: {
    SilderItem,
  },
  props: {
    items: Array,
  },
  data() {
    return {};
  },
};
