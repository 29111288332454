
import { Options, Vue } from "vue-class-component";
import CustomSlider from "@/components/CustomSlider.vue";

@Options({
  components: {
    CustomSlider,
  },
  data() {
    return {
      items: [
        {
          path: "/introduce/groupMatrix",
          name: "集团矩阵",
        },
        {
          path: "/introduce/countryside",
          name: "归农村",
        },
        {
          path: "/introduce/liyuan",
          name: "世外梨园",
        },
        {
          path: "https://wf.hzno.cn/",
          name: "万非芦荟",
          local: true,
        },
        // {
        //   path: "https://txsb.hzno.cn/",
        //   name: "天下商邦",
        //   local: true,
        // },
        // {
        //   path: "",
        //   name: "蕙芷",
        //   local: true,
        // },
        {
          path: "/introduce/delivery",
          name: "仓储配送",
        },
      ],
    };
  },
})
export default class Introduce extends Vue {}
