<template>
  <a
    :href="this.$props.toPath"
    :key="this.$props.toPath"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
    id="router_link_sider"
    :style="{ 'background-color': color }"
    target="_blank"
    v-if="this.$props.localHref"
  >
    {{ this.$props.title }}
  </a>
  <router-link
    v-else
    :to="this.$props.toPath"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
    id="router_link_sider"
    :style="{ 'background-color': color }"
  >
    {{ this.$props.title }}
  </router-link>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
  name: "SilderItem",
  props: {
    toPath: String,
    title: String,
    localHref: Boolean,
  },
  data() {
    return {
      color: "#fff",
    };
  },
  methods: {
    mouseEnter() {
      this.color = "#f1900020";
    },
    mouseLeave() {
      this.color = "#fff";
    },
  },
})
export default class SilderItem extends Vue {}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.router-link-active {
  text-decoration: none;
  color: #f19000;
}

#router_link_sider {
  height: 30px;
  width: 58px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
